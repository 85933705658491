@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #121212;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  color: #ffffff;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background: #121212;
}

body::-webkit-scrollbar-thumb {
  background-color: #494949;
}

div::-webkit-scrollbar {
  width: 3px;
}

div::-webkit-scrollbar-track {
  background: #121212;
}

div::-webkit-scrollbar-thumb {
  background-color: #494949;
  border-radius: 15px;
}

a {
  text-decoration: none;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
